/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-undef */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    showOnConnect: Boolean,
    refreshOnClose: { type: Boolean, default: false },
  };

  static targets = ['autofocus'];

  connect() {
    // NOTE: Prevents re-fetching the content based on the src attribute
    // For details see: https://labs.k.io/lessons-learned-with-hotwire/
    this.offcanvasTurboFrames.forEach((turboFrame) => turboFrame.removeAttribute('src'));

    if (!this.showOnConnectValue) return;

    const offcanvas = new bootstrap.Offcanvas(this.element);
    offcanvas.show();

    if (this.refreshOnCloseValue) {
      this.element.addEventListener('hidden.bs.offcanvas', this._onHidden.bind(this));
    }
  }

  get offcanvasTurboFrames() {
    return document.querySelectorAll("turbo-frame[id^='turbo-offcanvas']");
  }

  _hideOffcanvas() {
    this.offcanvasTurboFrames.forEach((turboFrame) => turboFrame.removeAttribute('src'));

    const offcanvas = bootstrap.Offcanvas.getInstance(this.element);
    offcanvas.hide();
    this.element.remove();
  }

  _onHidden() {
    Turbo.visit(window.location.href);
  }

  submitEnd(e) {
    const skipHandle = e.target.dataset.offcanvasSubmit === 'false';
    if (e.detail.success && !skipHandle) this._hideOffcanvas();
  }
}
