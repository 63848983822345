/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { Controller } from '@hotwired/stimulus';
import { I18n } from 'i18n-js';
import translations from '../../locales/translations.json';

const i18n = new I18n(translations);

export default class extends Controller {
  static targets = [
    'checkbox',
    'bulkActionControl',
    'actionControl',
    'bulkActionDestroyButton',
    'editBtn',
  ];

  static values = {
    selectedRulesCount: Number,
    tickPath: String,
    untickPath: String,
  };

  async _submit_rule_ids(autoassign_rule_ids, path) {
    try {
      const response = await fetch(path, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        },
        body: JSON.stringify({ autoassign_rule_ids }),
      });

      if (!response.ok) {
        alert('Something went wrong. Please contact support.');
        return;
      }

      const body = await response.json();
      this.selectedRulesCountValue = body.autoassign_rule_ids_count;
      this._toggleControls();
    } catch (error) {
      alert('Something went wrong. Please contact support.');
    }
  }

  _hideEditButtons() {
    this.editBtnTargets.forEach((button) => button.classList.add('d-none'));
  }

  _showEditButtons() {
    this.editBtnTargets.forEach((button) => button.classList.remove('d-none'));
  }

  bulkCheckboxChanged(event) {
    const state = event.target.checked;
    const autoassign_rule_ids = [];
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = state;
      autoassign_rule_ids.push(checkbox.value);
    });
    const path = (state == true ? this.tickPathValue : this.untickPathValue);
    this._submit_rule_ids(autoassign_rule_ids, path);
    this._toggleControls();
    if (state == true) {
      this._hideEditButtons();
    } else {
      this._showEditButtons();
    }
  }

  checkboxChanged(event) {
    const state = event.target.checked;
    const rule_id = event.target.value;
    const path = (state == true ? this.tickPathValue : this.untickPathValue);
    this._submit_rule_ids([rule_id], path);
  }

  _updateBulkControls() {
    const selectedCount = this.selectedRulesCountValue;
    if (this.hasBulkActionDestroyButtonTarget) this.bulkActionDestroyButtonTarget.innerHTML = i18n.t('web.settings.autoassign_rules.shared.bulk_action_buttons.bulk_destroy', { count: selectedCount });
  }

  _toggleCardControls() {
    if (this.selectedRulesCountValue > 0) {
      this.actionControlTargets.forEach((control) => {
        control.classList.add(control.dataset.hideClass);
      });
      this._hideEditButtons();
      return;
    }

    this.actionControlTargets.forEach((control) => {
      control.classList.remove(control.dataset.hideClass);
      this._showEditButtons();
    });
  }

  _toggleBulkControls() {
    if (this.selectedRulesCountValue > 0) {
      this.bulkActionControlTargets.forEach((control) => {
        control.classList.remove(control.dataset.hideClass);
      });
      return;
    }

    this.bulkActionControlTargets.forEach((control) => {
      control.classList.add(control.dataset.hideClass);
    });
  }

  _toggleControls() {
    this._updateBulkControls();
    this._toggleBulkControls();
    this._toggleCardControls();
  }

  connect() {
    this._toggleControls();
  }
}
