/* eslint-disable no-undef */
import { Controller } from '@hotwired/stimulus';
import { I18n } from 'i18n-js';
import translations from '../locales/translations.json';

const i18n = new I18n(translations);

export default class extends Controller {
  static values = {
    content: { type: String, default: i18n.t('web.autoupdated_field') },
    icons: Array,
    hideIcon: Boolean,
  };

  _buildAttributes() {
    let tooltipContent = this.contentValue;

    if (this.hasIconsValue && this.iconsValue.length > 0) {
      const iconsHtml = this.iconsValue.map(icon => {
        return `<div class="d-flex align-items-center ms-1">
                  <span class="me-1 fw-600">${icon.name}</span>
                  <img src="${icon.icon}" alt="${icon.name}" class="me-1" width="22" height="22">
                </div>`;
      }).join('');

      tooltipContent = `<div class="d-flex flex-wrap align-items-baseline">
                          <span>${this.contentValue}</span>
                          ${iconsHtml}
                        </div>`;
    }

    const tooltipTemplate = '<div class="tooltip tooltip_light" role="tooltip"><div class="tooltip-inner d-flex gap-1 align-items-baseline justify-content-start text-nowrap p-2"></div></div>';
    const tooltipOptions = {
      'data-controller': 'bs-tooltip',
      'data-bs-toggle': 'tooltip',
      'data-bs-placement': 'bottom',
      'data-bs-html': 'true',
      'data-bs-offset': '0,0',
      'data-bs-popper-config': { placement: 'bottom-start' },
      'data-bs-template': tooltipTemplate,
    }
    return { title: tooltipContent, data: tooltipOptions };
  }

  _appendPlugIcon(container) {
    container.insertAdjacentHTML('beforeend', '<i class="bi bi-plug ms-1"></i>');
  }

  toggleBackground() {
    this.element.closest('.hoverable').classList.toggle('bg-light-gray');
  }

  connect() {
    const attrs = this._buildAttributes();
    this.element.setAttribute('title', attrs.title);
    Object.entries(attrs.data).forEach(([key, value]) => {
      if (key === 'data-controller') {
        const existingValue = this.element.getAttribute(key) || '';
        this.element.setAttribute(key, `${existingValue} ${value}`.trim());
      } else if (typeof value === 'object') {
        this.element.setAttribute(key, JSON.stringify(value));
      } else {
        this.element.setAttribute(key, value);
      }
    });
    if (!this.hideIconValue) {
      this._appendPlugIcon(this.element);
    }
    new bootstrap.Tooltip(this.element);
  }
}
