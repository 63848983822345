import c0 from './accordion_caret_controller';
import c1 from './accounting_accounts_loader_spinner_controller';
import c2 from './accounting_connection_controller';
import c3 from './app/bulk_actions_controller';
import c4 from './app_autoassign_rule/bulk_actions_controller';
import c5 from './aside_form_controller';
import c6 from './asset_type/depreciation_settings_controller';
import c7 from './asset_type/restrictions_controller';
import c8 from './assignation_type_controller';
import c9 from './assignees_import/action_controller';
import c10 from './assignees_import/section_controller';
import c11 from './audit/audit_asset_controller';
import c12 from './auto_refresh_controller';
import c13 from './autosize_textarea_controller';
import c14 from './autosubmit_form_controller';
import c15 from './avatar_upload_controller';
import c16 from './bootstrap_custom_controller';
import c17 from './brand_selector_controller';
import c18 from './bs_tooltip_controller';
import c19 from './bulk_action_controller';
import c20 from './bulk_actionable_controller';
import c21 from './category/form_controller';
import c22 from './category/icon_select_controller';
import c23 from './chart/annotation_controller';
import c24 from './chart/tooltip_controller';
import c25 from './clipboard_controller';
import c26 from './column_controller';
import c27 from './company_integration_controller';
import c28 from './contract_info_controller';
import c29 from './csvbox2_controller';
import c30 from './csvbox_controller';
import c31 from './custom_tooltip_controller';
import c32 from './datetime_range_controller';
import c33 from './department/bulk_actions_controller';
import c34 from './device/archive_controller';
import c35 from './device/aside_qr_scanner_controller';
import c36 from './device/brand_autocomplete_controller';
import c37 from './device/conditional_fields_controller';
import c38 from './device/duplicate_controller';
import c39 from './device/inspection_list_controller';
import c40 from './device/inspection_type_controller';
import c41 from './device/license_installation_controller';
import c42 from './device/multiple_new_controller';
import c43 from './device/ownership_form_controller';
import c44 from './device/required_photos_controller';
import c45 from './device/scan_asset_label_controller';
import c46 from './device/serial_number_controller';
import c47 from './device/update_template_controller';
import c48 from './device/warranty_on_date_controller';
import c49 from './dropdown_caret_controller';
import c50 from './dropdown_select_controller';
import c51 from './dropzone_controller';
import c52 from './fake_submit_controller';
import c53 from './file_upload_controller';
import c54 from './filterable_controller';
import c55 from './filterable_filters_controller';
import c56 from './filterbar_controller';
import c57 from './form_blocker_controller';
import c58 from './form_watcher_controller';
import c59 from './hr_package_spinner_controller';
import c60 from './inspection_form_controller';
import c61 from './interactive_field_controller';
import c62 from './legal_entity/bulk_actions_controller';
import c63 from './license/asset_installation_controller';
import c64 from './license/brand_autocomplete_controller';
import c65 from './license/license_template_controller';
import c66 from './license/notice_terms_controller';
import c67 from './license/user_assigning_controller';
import c68 from './link_controller';
import c69 from './linkable_option_controller';
import c70 from './location/bulk_actions_controller';
import c71 from './main_search_controller';
import c72 from './matching_rule_form_controller';
import c73 from './navigation_controller';
import c74 from './notification/flash_controller';
import c75 from './notification/toast_controller';
import c76 from './paddle/payment_form_controller';
import c77 from './paddle/update_card_form_controller';
import c78 from './partner_portal/partner_company_controller';
import c79 from './partner_portal/payment_form_controller';
import c80 from './partner_portal_access_controller';
import c81 from './payment_subscription_controller';
import c82 from './persist_scroll_controller';
import c83 from './phone_scanner_controller';
import c84 from './prefill_controller';
import c85 from './question_controller';
import c86 from './radio_tabs_controller';
import c87 from './redirect_controller';
import c88 from './remote_checkbox_controller';
import c89 from './scroll_aside_form_controller';
import c90 from './scroll_controller';
import c91 from './select/org_entities_controller';
import c92 from './select_tabs_controller';
import c93 from './show_more_controller';
import c94 from './spinner_controller';
import c95 from './sso_controller';
import c96 from './sublocation/bulk_actions_controller';
import c97 from './survey/accept_eula_controller';
import c98 from './survey/qr_scanner_controller';
import c99 from './survey/ranking_controller';
import c100 from './survey/review_controller';
import c101 from './survey/settings_photo_controller';
import c102 from './switch_controller';
import c103 from './synchronization_controller';
import c104 from './task_controller';
import c105 from './tomselect_controller';
import c106 from './turbo_modal_controller';
import c107 from './turbo_offcanvas_controller';
import c108 from './user/asset_assigning_controller';
import c109 from './user/bulk_actions_controller';
import c110 from './user/bulk_edit_form_controller';
import c111 from './user/license_assigning_controller';
import c112 from './user/radio_tabs_controller';
import c113 from './user/role_restriction_controller';
import c114 from './webhook_endpoint_controller';
export const definitions = [
	{identifier: 'accordion-caret', controllerConstructor: c0},
	{identifier: 'accounting-accounts-loader-spinner', controllerConstructor: c1},
	{identifier: 'accounting-connection', controllerConstructor: c2},
	{identifier: 'app--bulk-actions', controllerConstructor: c3},
	{identifier: 'app-autoassign-rule--bulk-actions', controllerConstructor: c4},
	{identifier: 'aside-form', controllerConstructor: c5},
	{identifier: 'asset-type--depreciation-settings', controllerConstructor: c6},
	{identifier: 'asset-type--restrictions', controllerConstructor: c7},
	{identifier: 'assignation-type', controllerConstructor: c8},
	{identifier: 'assignees-import--action', controllerConstructor: c9},
	{identifier: 'assignees-import--section', controllerConstructor: c10},
	{identifier: 'audit--audit-asset', controllerConstructor: c11},
	{identifier: 'auto-refresh', controllerConstructor: c12},
	{identifier: 'autosize-textarea', controllerConstructor: c13},
	{identifier: 'autosubmit-form', controllerConstructor: c14},
	{identifier: 'avatar-upload', controllerConstructor: c15},
	{identifier: 'bootstrap-custom', controllerConstructor: c16},
	{identifier: 'brand-selector', controllerConstructor: c17},
	{identifier: 'bs-tooltip', controllerConstructor: c18},
	{identifier: 'bulk-action', controllerConstructor: c19},
	{identifier: 'bulk-actionable', controllerConstructor: c20},
	{identifier: 'category--form', controllerConstructor: c21},
	{identifier: 'category--icon-select', controllerConstructor: c22},
	{identifier: 'chart--annotation', controllerConstructor: c23},
	{identifier: 'chart--tooltip', controllerConstructor: c24},
	{identifier: 'clipboard', controllerConstructor: c25},
	{identifier: 'column', controllerConstructor: c26},
	{identifier: 'company-integration', controllerConstructor: c27},
	{identifier: 'contract-info', controllerConstructor: c28},
	{identifier: 'csvbox2', controllerConstructor: c29},
	{identifier: 'csvbox', controllerConstructor: c30},
	{identifier: 'custom-tooltip', controllerConstructor: c31},
	{identifier: 'datetime-range', controllerConstructor: c32},
	{identifier: 'department--bulk-actions', controllerConstructor: c33},
	{identifier: 'device--archive', controllerConstructor: c34},
	{identifier: 'device--aside-qr-scanner', controllerConstructor: c35},
	{identifier: 'device--brand-autocomplete', controllerConstructor: c36},
	{identifier: 'device--conditional-fields', controllerConstructor: c37},
	{identifier: 'device--duplicate', controllerConstructor: c38},
	{identifier: 'device--inspection-list', controllerConstructor: c39},
	{identifier: 'device--inspection-type', controllerConstructor: c40},
	{identifier: 'device--license-installation', controllerConstructor: c41},
	{identifier: 'device--multiple-new', controllerConstructor: c42},
	{identifier: 'device--ownership-form', controllerConstructor: c43},
	{identifier: 'device--required-photos', controllerConstructor: c44},
	{identifier: 'device--scan-asset-label', controllerConstructor: c45},
	{identifier: 'device--serial-number', controllerConstructor: c46},
	{identifier: 'device--update-template', controllerConstructor: c47},
	{identifier: 'device--warranty-on-date', controllerConstructor: c48},
	{identifier: 'dropdown-caret', controllerConstructor: c49},
	{identifier: 'dropdown-select', controllerConstructor: c50},
	{identifier: 'dropzone', controllerConstructor: c51},
	{identifier: 'fake-submit', controllerConstructor: c52},
	{identifier: 'file-upload', controllerConstructor: c53},
	{identifier: 'filterable', controllerConstructor: c54},
	{identifier: 'filterable-filters', controllerConstructor: c55},
	{identifier: 'filterbar', controllerConstructor: c56},
	{identifier: 'form-blocker', controllerConstructor: c57},
	{identifier: 'form-watcher', controllerConstructor: c58},
	{identifier: 'hr-package-spinner', controllerConstructor: c59},
	{identifier: 'inspection-form', controllerConstructor: c60},
	{identifier: 'interactive-field', controllerConstructor: c61},
	{identifier: 'legal-entity--bulk-actions', controllerConstructor: c62},
	{identifier: 'license--asset-installation', controllerConstructor: c63},
	{identifier: 'license--brand-autocomplete', controllerConstructor: c64},
	{identifier: 'license--license-template', controllerConstructor: c65},
	{identifier: 'license--notice-terms', controllerConstructor: c66},
	{identifier: 'license--user-assigning', controllerConstructor: c67},
	{identifier: 'link', controllerConstructor: c68},
	{identifier: 'linkable-option', controllerConstructor: c69},
	{identifier: 'location--bulk-actions', controllerConstructor: c70},
	{identifier: 'main-search', controllerConstructor: c71},
	{identifier: 'matching-rule-form', controllerConstructor: c72},
	{identifier: 'navigation', controllerConstructor: c73},
	{identifier: 'notification--flash', controllerConstructor: c74},
	{identifier: 'notification--toast', controllerConstructor: c75},
	{identifier: 'paddle--payment-form', controllerConstructor: c76},
	{identifier: 'paddle--update-card-form', controllerConstructor: c77},
	{identifier: 'partner-portal--partner-company', controllerConstructor: c78},
	{identifier: 'partner-portal--payment-form', controllerConstructor: c79},
	{identifier: 'partner-portal-access', controllerConstructor: c80},
	{identifier: 'payment-subscription', controllerConstructor: c81},
	{identifier: 'persist-scroll', controllerConstructor: c82},
	{identifier: 'phone-scanner', controllerConstructor: c83},
	{identifier: 'prefill', controllerConstructor: c84},
	{identifier: 'question', controllerConstructor: c85},
	{identifier: 'radio-tabs', controllerConstructor: c86},
	{identifier: 'redirect', controllerConstructor: c87},
	{identifier: 'remote-checkbox', controllerConstructor: c88},
	{identifier: 'scroll-aside-form', controllerConstructor: c89},
	{identifier: 'scroll', controllerConstructor: c90},
	{identifier: 'select--org-entities', controllerConstructor: c91},
	{identifier: 'select-tabs', controllerConstructor: c92},
	{identifier: 'show-more', controllerConstructor: c93},
	{identifier: 'spinner', controllerConstructor: c94},
	{identifier: 'sso', controllerConstructor: c95},
	{identifier: 'sublocation--bulk-actions', controllerConstructor: c96},
	{identifier: 'survey--accept-eula', controllerConstructor: c97},
	{identifier: 'survey--qr-scanner', controllerConstructor: c98},
	{identifier: 'survey--ranking', controllerConstructor: c99},
	{identifier: 'survey--review', controllerConstructor: c100},
	{identifier: 'survey--settings-photo', controllerConstructor: c101},
	{identifier: 'switch', controllerConstructor: c102},
	{identifier: 'synchronization', controllerConstructor: c103},
	{identifier: 'task', controllerConstructor: c104},
	{identifier: 'tomselect', controllerConstructor: c105},
	{identifier: 'turbo-modal', controllerConstructor: c106},
	{identifier: 'turbo-offcanvas', controllerConstructor: c107},
	{identifier: 'user--asset-assigning', controllerConstructor: c108},
	{identifier: 'user--bulk-actions', controllerConstructor: c109},
	{identifier: 'user--bulk-edit-form', controllerConstructor: c110},
	{identifier: 'user--license-assigning', controllerConstructor: c111},
	{identifier: 'user--radio-tabs', controllerConstructor: c112},
	{identifier: 'user--role-restriction', controllerConstructor: c113},
	{identifier: 'webhook-endpoint', controllerConstructor: c114},
];
