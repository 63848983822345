/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { Controller } from '@hotwired/stimulus';
import { I18n } from 'i18n-js';
import translations from '../../locales/translations.json';

const i18n = new I18n(translations);

export default class extends Controller {
  static targets = [
    'checkbox',
    'bulkActionControl',
    'actionControl',
    'bulkActionIgnoreButton',
    'bulkActionUnapproveButton',
    'bulkActionOnboardButton',
    'bulkActionDiscoverButton',
    'selectAllAppsPanel',
    'selectAllSelectedAppsCountText',
    'editBtn',
    'selectAllLink'
  ];

  static values = {
    selectedAppsCount: Number,
    allAppsCount: Number,
    tickPath: String,
    untickPath: String,
    allPath: String,
  };

  async _submit_app_ids(app_ids, path) {
    try {
      const response = await fetch(path, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        },
        body: JSON.stringify({ app_ids }),
      });

      if (!response.ok) {
        alert('Something went wrong. Please contact support.');
        return;
      }

      const body = await response.json();
      this.selectedAppsCountValue = body.app_ids_count;

      if (body.app_filtered_count != null) {
        this.allAppsCountValue = body.app_filtered_count;
      }

      this._updateSelectAllLink();
      this._toggleControls();
    } catch (error) {
      alert('Something went wrong. Please contact support.');
    }
  }

  _showSelectAllAppsPanel() {
    this.selectAllAppsPanelTarget.classList.remove('d-none');
  }

  _hideSelectAllAppsPanel() {
    this.selectAllAppsPanelTarget.classList.add('d-none');
  }

  _hideEditButtons() {
    this.editBtnTargets.forEach((button) => button.classList.add('d-none'));
  }

  _showEditButtons() {
    this.editBtnTargets.forEach((button) => button.classList.remove('d-none'));
  }

  bulkCheckboxChanged(event) {
    const state = event.target.checked;
    const app_ids = [];
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = state;
      app_ids.push(checkbox.value);
    });

    const currentFilter = new URLSearchParams(location.search);
    const path = (state ? this.tickPathValue : this.untickPathValue);
    const url = new URL(path, window.location.origin);

    currentFilter.forEach((value, key) => {
      url.searchParams.append(key, value);
    });

    this._submit_app_ids(app_ids, url.toString()).then(() => {
      if (this.selectedAppsCountValue === 0) {
        this._hideSelectAllAppsPanel();
        event.target.checked = false;
        return;
      }
      if (this.selectedAppsCountValue < 20) {
        this._hideSelectAllAppsPanel();
        return;
      }

      if (state) {
        this._showSelectAllAppsPanel();
        this._hideEditButtons();
      } else {
        this._hideSelectAllAppsPanel();
        this._showEditButtons();
      }
    });
  }

  checkboxChanged(event) {
    const state = event.target.checked;
    const app_id = event.target.value;
    const path = (state == true ? this.tickPathValue : this.untickPathValue);
    this._submit_app_ids([app_id], path);
    this._hideSelectAllAppsPanel();
  }

  bulkActionSelectAll(event) {
    event.preventDefault();

    const currentFilter = new URLSearchParams(location.search);
    const path = this.allPathValue;
    const url = new URL(path, window.location.origin);
    currentFilter.forEach((value, key) => {
      url.searchParams.append(key, value);
    });
    const finalPath = url.toString();

    fetch(finalPath, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      }
    }).then(response => {
      if (response.ok) {
        window.location.reload();
      } else {
        alert('Something went wrong. Please contact support.');
      }
    });
  }

  _updateBulkControls() {
    const selectedCount = this.selectedAppsCountValue;
    this.selectAllSelectedAppsCountTextTarget.innerHTML = i18n.t('web.apps.bulk_action_progress_panel.select_all_selected_html', { selected_apps_count: selectedCount });
    if (this.hasBulkActionIgnoreButtonTarget) this.bulkActionIgnoreButtonTarget.innerHTML = i18n.t('web.apps.bulk_action_buttons.bulk_ignore', { count: selectedCount });
    if (this.hasBulkActionUnapproveButtonTarget) this.bulkActionUnapproveButtonTarget.innerHTML = i18n.t('web.apps.bulk_action_buttons.bulk_unapprove', { count: selectedCount });
    if (this.hasBulkActionOnboardButtonTarget) this.bulkActionOnboardButtonTarget.innerHTML = i18n.t('web.apps.bulk_action_buttons.bulk_onboard', { count: selectedCount });
    if (this.hasBulkActionDiscoverButtonTarget) this.bulkActionDiscoverButtonTarget.innerHTML = i18n.t('web.apps.bulk_action_buttons.bulk_discover', { count: selectedCount });
  }

  _toggleCardControls() {
    if (this.selectedAppsCountValue > 0) {
      this.actionControlTargets.forEach((control) => {
        control.classList.add(control.dataset.hideClass);
      });
      this._hideEditButtons();
      return;
    }

    this.actionControlTargets.forEach((control) => {
      control.classList.remove(control.dataset.hideClass);
      this._showEditButtons();
    });
  }

  _toggleBulkControls() {
    if (this.selectedAppsCountValue > 0) {
      this.bulkActionControlTargets.forEach((control) => {
        control.classList.remove(control.dataset.hideClass);
      });
      return;
    }

    this.bulkActionControlTargets.forEach((control) => {
      control.classList.add(control.dataset.hideClass);
    });
  }

  _toggleControls() {
    this._updateBulkControls();
    this._toggleBulkControls();
    this._toggleCardControls();
  }

  _updateSelectAllLink() {
    if (this.hasSelectAllLinkTarget) {
      this.selectAllLinkTarget.innerHTML = i18n.t('web.apps.bulk_action_progress_panel.select_all_link', { count: this.allAppsCountValue });
    }
  }

  connect() {
    this._toggleControls();
  }
}
